import React from 'react'

const Thankyou = () => {
    return (
        <div className='ty'>
            <div className='inner container_lg'>
                <div className='text text-center'>
                    <h3 className='section_title_lg color_white'>Thank You</h3>
                    <hr className='line' />
                    <p className='msg color_white'>We will get back to you shortly...</p>
                </div>
            </div>
        </div>
    )
}

export default Thankyou
