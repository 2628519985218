import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ThankYou from '../../components/Thank you'

const IndexPage = () => {
    const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        pageBy(uri: "/thank-you/") {
          title
        }
      }
    }
  `)

  let page_title = initial_data_query.wpgraphql;
  let title = page_title.pageBy.title;
  

  return(
    <div id="thankyou_page">
        <Layout>
          {/* {
            console.log(page_title.pageBy.title)
          } */}

             <SEO title={title} /> 
        
            <div className="thankyou_wrapper">
                <ThankYou/>
            </div>
        </Layout>
    </div>
  )
}

export default IndexPage
